import React from "react";
import withLayout from "../layout";
import Login from "../components/Login";
import { FormattedMessage } from "react-intl";

const LoginPage = () => <Login />;

const customProps = {
    localeKey: "loginregister",
    withAuth: false,
    navigateTo: "/",
    title: <FormattedMessage id="login.title" />,
};

export default withLayout(customProps)(LoginPage);
